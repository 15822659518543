
import { Tag } from "@/models/entities/tag.interface";
import { ProductCategory } from "@/models/entities/product-category.interface";
import { TablesDialogProperties } from "@/models/utils/tables-dialog-properties.interface";
import { MeasureUnitType } from "@/models/entities/measureUnitType.interface";
import { CustomerType } from "@/models/entities/customer-type.interface";
import { CascadeDelete } from "@/models/utils/cascade-delete.interface";
import { OnDeleteCategory } from "@/models/utils/on-delete-category.interface";
import { City } from "@/models/entities/city.interface";
import { CityUpdate } from "@/models/utils/city-update.interface";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { tags } from "@/services/api/tags.service";
import { productCategories } from "@/services/api/productCategories.service";
import { measureUnitTypes } from "@/services/api/measureUnitTypes.service";
import { customerTypes } from "@/services/api/customer-type.service";
import { cities } from "@/services/api/cities.service";
import { required } from "@/helpers/rules";
import TagDialog from "./TagDialog.vue";
import CategoryDialog from "./CategoryDialog.vue";
import MeasureDialog from "./MeasureDialog.vue";
import LocalitiesDialog from "./LocalitiesDialog.vue";
const CustomerTypeDialog = () =>
  import(
    /* webpackChunkName: "CustomerTypeDialog" */ "./CustomerTypes/CustomerTypeDialog.vue"
  );
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component({
  components: {
    TagDialog,
    CategoryDialog,
    MeasureDialog,
    CustomerTypeDialog,
    LocalitiesDialog,
  },
})
export default class Tables extends Vue {
  private dialogDelete = false;
  private onDeleteMessage = "";
  private onDeleteSpecialMessage = "";
  private showOnDeleteSpecialMessage = false;
  private itemsConnectedToNotDelete = null;
  private areItemsConnected = false;
  private itemToDelete = null;
  private typeToDelete = "";
  private colors = [
    ["#3b3b3b", "#9dd62d"],
    ["#9c9c9c", "#29ba46"],
    ["#d13819", "#1b91b5"],
    ["#cc8b1b", "#1367d4"],
    ["#836c61", "#7d1ed6"],
  ];
  private localitiesHeader = [
    {
      text: "ID",
      value: "id",
      width: "7%",
    },
    {
      text: "Nome",
      value: "name",
      width: "40%",
    },
    {
      text: "Lunedì",
      value: 0,
      filterable: false,
      sortable: false,
      width: "2%",
    },
    {
      text: "Martedì",
      value: 1,
      filterable: false,
      sortable: false,
      width: "2%",
    },
    {
      text: "Mercoledì",
      value: 2,
      filterable: false,
      sortable: false,
      width: "2%",
    },
    {
      text: "Giovedì",
      value: 3,
      filterable: false,
      sortable: false,
      width: "2%",
    },
    {
      text: "Venerdì",
      value: 4,
      filterable: false,
      sortable: false,
      width: "2%",
    },
    {
      text: "Sabato",
      value: 5,
      filterable: false,
      sortable: false,
      width: "2%",
    },
    {
      text: "Giorni di preavviso",
      filterable: false,
      sortable: false,
      width: "19%",
    },
    {
      text: "Massimo preavviso",
      filterable: false,
      sortable: false,
      width: "15%",
    },
    {
      text: "Abilitata",
      filterable: false,
      sortable: false,
      width: "4%",
    },
    {
      text: "Azioni",
      filterable: false,
      sortable: false,
      width: "3%",
      align: "center",
    },
  ];
  private localitiesShippingDays = [0, 1, 2, 3, 4, 5];
  private searchInLocalities = "";
  private cityUpdate = {} as CityUpdate;
  private forewaringDaysTimeout: number;
  private themeColor =
    "background-color: " +
    this.$vuetify.theme.themes.light.primary["lighten2"] +
    ";";
  private itemToAdd = null;
  private itemPropsToPass = {
    showTagDialog: false,
    showCategoryDialog: false,
    showMeasureDialog: false,
    showCustomerTypeDialog: false,
    showLocalitiesDialog: false,
    title: "",
    label: "",
    itemType: "",
  };
  private tableLists = {
    tags: [] as Tag[],
    productCategories: [] as ProductCategory[],
    measureUnitTypes: [] as MeasureUnitType[],
    customerTypes: [] as CustomerType[],
    itemsBeforeDelete: [] as CascadeDelete[],
    cities: [] as City[],
  };
  private cardTitleHeight = 0;

  private required = required;

  async created(): Promise<void> {
    if (this.$vuetify.breakpoint.xs) {
      this.$router.push({ name: "dashboard" });
    } else {
      await Promise.all([
        this.getTagList(),
        this.getCategoryList(),
        this.getMeasureUnitTypes(),
        this.getCustomerTypes(),
        this.getUsedCities(),
      ]);
    }
  }

  mounted(): void {
    if (this.$refs.cardTitle as HTMLElement) {
      this.cardTitleHeight = (this.$refs.cardTitle as HTMLElement).clientHeight;
    }
  }

  get categoriesWithTag(): ProductCategory[] {
    let categories = [] as ProductCategory[];
    if (this.tableLists.tags.some((tag) => tag.categoryID == null))
      categories.push({ id: 0, categoryName: "Globali" } as ProductCategory);
    categories = categories.concat(
      this.tableLists.productCategories.filter((category) =>
        this.tableLists.tags.some((tag) => tag.categoryID == category.id)
      )
    );
    return categories;
  }

  private async getUsedCities(): Promise<void> {
    overlayModule.showOverlay();
    const c = await cities.getAllUsedCities();
    this.$set(this.tableLists, "cities", c.data);
  }

  private async getTagList() {
    overlayModule.showOverlay();
    const t = await tags.getAllTags();
    this.$set(this.tableLists, "tags", t.data);
  }

  private async getCategoryList() {
    overlayModule.showOverlay();
    const p = await productCategories.getAllProductCategories();
    this.$set(this.tableLists, "productCategories", p.data);
  }

  private async getMeasureUnitTypes() {
    overlayModule.showOverlay();
    const m = await measureUnitTypes.getAllMeasureUnitTypes();
    this.$set(this.tableLists, "measureUnitTypes", m.data);
  }

  private async getCustomerTypes() {
    overlayModule.showOverlay();
    const c = await customerTypes.getAllCustomerType();
    this.$set(this.tableLists, "customerTypes", c.data);
  }

  private async beforeDelete(type: string) {
    let i;
    switch (type) {
      case "categoryName":
        overlayModule.showOverlay();
        i = await productCategories.onDeleteCategory(this.itemToDelete);
        this.itemsConnectedToNotDelete = i.data;
        break;
      case "customerTypeName":
        overlayModule.showOverlay();
        i = await customerTypes.onDeleteCustomerType(this.itemToDelete);
        this.itemsConnectedToNotDelete = i.data;
        break;
      case "measureUnitTypeName":
        overlayModule.showOverlay();
        i = await measureUnitTypes.onDeleteMeasureUnit(this.itemToDelete);
        this.itemsConnectedToNotDelete = i.data;
        break;
    }
  }

  private openItemDialog(type: string, label: string, str: string): void {
    this.$set(this.itemPropsToPass, "itemType", type);
    this.$set(this.itemPropsToPass, "label", label);
    this.$set(this.itemPropsToPass, "title", "Inserimento " + str);
    switch (this.itemPropsToPass.itemType) {
      case "tag":
        this.$set(this.itemPropsToPass, "showTagDialog", true);
        break;
      case "category":
        this.$set(this.itemPropsToPass, "showCategoryDialog", true);
        break;
      case "measure":
        this.$set(this.itemPropsToPass, "showMeasureDialog", true);
        break;
      case "customerType":
        this.$set(this.itemPropsToPass, "showCustomerTypeDialog", true);
        break;
      case "locality":
        this.$set(this.itemPropsToPass, "showLocalitiesDialog", true);
        break;
    }
  }

  private closeItemDialog(data: TablesDialogProperties): void {
    switch (data.itemType) {
      case "tag":
        this.$set(this.itemPropsToPass, "showTagDialog", false);
        break;
      case "category":
        this.$set(this.itemPropsToPass, "showCategoryDialog", false);
        break;
      case "measure":
        this.$set(this.itemPropsToPass, "showMeasureDialog", false);
        break;
      case "customerType":
        this.$set(this.itemPropsToPass, "showCustomerTypeDialog", false);
        break;
      case "locality":
        this.$set(this.itemPropsToPass, "showLocalitiesDialog", false);
        this.getUsedCities();
        break;
    }
  }

  public async saveItem(data: TablesDialogProperties): Promise<void> {
    switch (data.itemType) {
      case "tag":
        this.itemToAdd = {} as Tag;
        this.itemToAdd.tagName = data.name;
        this.itemToAdd.color = data.color;
        this.itemToAdd.categoryID = data.categoryId;
        await tags.saveTag(this.itemToAdd);
        snackbarModule.showSnackbar({
          message: "Salvataggio avvenuto con successo",
          type: "success",
        });
        setTimeout(() => snackbarModule.hideSnackbar(), 2000);
        this.getTagList();
        this.$set(this.itemPropsToPass, "showTagDialog", false);
        break;
      case "category":
        this.itemToAdd = {} as ProductCategory;
        this.itemToAdd.companyId = data.companyId;
        this.itemToAdd.categoryName = data.name;
        this.itemToAdd.categoryDescription = data.description;
        this.itemToAdd.isEnabled = true;
        await productCategories.saveProductCategory(this.itemToAdd);
        snackbarModule.showSnackbar({
          message: "Salvataggio avvenuto con successo",
          type: "success",
        });
        setTimeout(() => snackbarModule.hideSnackbar(), 2000);
        this.getCategoryList();
        this.$set(this.itemPropsToPass, "showCategoryDialog", false);
        break;
      case "measure":
        this.itemToAdd = {} as MeasureUnitType;
        this.itemToAdd.measureUnitTypeName = data.name;
        this.itemToAdd.needsConversion = true;
        await measureUnitTypes.saveMeasureUnit(this.itemToAdd);
        snackbarModule.showSnackbar({
          message: "Salvataggio avvenuto con successo",
          type: "success",
        });
        setTimeout(() => snackbarModule.hideSnackbar(), 2000);
        this.getMeasureUnitTypes();
        this.$set(this.itemPropsToPass, "showMeasureDialog", false);
        break;
      case "customerType":
        this.itemToAdd = {} as CustomerType;
        this.itemToAdd.customerTypeName = data.name;
        this.itemToAdd.isPrivate = data.isPrivate;
        await customerTypes.saveCustomerType(this.itemToAdd);
        snackbarModule.showSnackbar({
          message: "Salvataggio avvenuto con successo",
          type: "success",
        });
        setTimeout(() => snackbarModule.hideSnackbar(), 2000);
        this.getCustomerTypes();
        this.$set(this.itemPropsToPass, "showCustomerTypeDialog", false);
        break;
    }
  }

  public async updateLocality(
    id: number,
    deliveryDays: number[],
    forewarningDays: string,
    forewarningTime: string,
    isEnabled: boolean
  ): Promise<void> {
    if (this.forewaringDaysTimeout) {
      clearTimeout(this.forewaringDaysTimeout);
    }
    this.forewaringDaysTimeout = setTimeout(async () => {
      this.cityUpdate.cityID = id;
      if (deliveryDays.length != 0) {
        this.cityUpdate.deliveryDays = deliveryDays.sort();
      } else {
        this.cityUpdate.deliveryDays = [] as number[];
      }
      if (forewarningDays) {
        this.cityUpdate.forewarningDays = parseInt(forewarningDays, 10);
      } else {
        this.cityUpdate.forewarningDays = 0;
      }
      if (forewarningTime) {
        this.cityUpdate.forewarningTime = forewarningTime;
      } else {
        this.cityUpdate.forewarningTime = "00:00";
      }
      this.cityUpdate.isEnabled = isEnabled;
      await cities
        .updateCity(this.cityUpdate)
        .catch(() => this.getUsedCities());
    }, 500);
  }

  private async onDelete(
    id: number,
    obj: unknown,
    name: string,
    str: string
  ): Promise<void> {
    if (name == "categoryName") {
      this.itemToDelete = id;
      this.typeToDelete = name;
      this.onDeleteMessage =
        "Sei sicuro di volere eliminare " +
        str +
        " " +
        obj[name].toUpperCase() +
        "?";
      this.itemsConnectedToNotDelete = {} as OnDeleteCategory;
      await this.beforeDelete(name);
      if (
        this.itemsConnectedToNotDelete.tags.length != 0 ||
        this.itemsConnectedToNotDelete.products.length != 0 ||
        this.itemsConnectedToNotDelete.productPriceBooks.length != 0
      ) {
        this.onDeleteSpecialMessage =
          "Attenzione: eliminare prima tag, prodotti e listini prezzo associati";
        this.showOnDeleteSpecialMessage = true;
        this.areItemsConnected = true;
        this.itemsConnectedToNotDelete = null;
      } else {
        this.onDeleteSpecialMessage = "";
        this.showOnDeleteSpecialMessage = false;
        this.areItemsConnected = false;
      }
      this.dialogDelete = true;
    } else if (name == "customerTypeName") {
      this.itemToDelete = id;
      this.typeToDelete = name;
      this.onDeleteMessage =
        "Sei sicuro di volere eliminare " +
        str +
        " " +
        obj[name].toUpperCase() +
        "?";
      await this.beforeDelete(name);
      if (
        this.itemsConnectedToNotDelete.priceBooks.length != 0 ||
        this.itemsConnectedToNotDelete.customers.length != 0
      ) {
        this.onDeleteSpecialMessage =
          "Attenzione: rimuovere prima tutte le associazioni con i listini ed i clienti di questa tipologia";
        this.showOnDeleteSpecialMessage = true;
        this.areItemsConnected = true;
      } else {
        this.showOnDeleteSpecialMessage = false;
        this.areItemsConnected = false;
      }
      this.dialogDelete = true;
    } else if (name == "measureUnitTypeName") {
      this.itemToDelete = id;
      this.typeToDelete = name;
      this.onDeleteMessage =
        "Sei sicuro di volere eliminare " +
        str +
        " " +
        obj[name].toUpperCase() +
        "?";
      await this.beforeDelete(name);
      if (this.itemsConnectedToNotDelete.length != 0) {
        this.onDeleteSpecialMessage =
          "Attenzione: rimuovere prima tutte le associazioni con i listini";
        this.showOnDeleteSpecialMessage = true;
        this.areItemsConnected = true;
      } else {
        this.showOnDeleteSpecialMessage = false;
        this.areItemsConnected = false;
      }
      this.dialogDelete = true;
    } else {
      this.areItemsConnected = false;
      this.itemToDelete = id;
      this.typeToDelete = name;
      this.onDeleteMessage =
        "Sei sicuro di volere eliminare " +
        str +
        " " +
        obj[name].toUpperCase() +
        "?";
      this.onDeleteSpecialMessage = "";
      this.dialogDelete = true;
    }
  }

  private closeDialogDelete(): void {
    this.$set(this.tableLists, "itemsBeforeDelete", [] as CascadeDelete[]);
    this.onDeleteSpecialMessage = "";
    this.showOnDeleteSpecialMessage = false;
    this.dialogDelete = false;
  }

  private async deleteItem(): Promise<void> {
    switch (this.typeToDelete) {
      case "tagName":
        await tags.deleteTag(this.itemToDelete);
        this.getTagList();
        break;
      case "categoryName":
        await productCategories.deleteProductCategory(this.itemToDelete);
        this.getTagList();
        this.getCategoryList();
        break;
      case "measureUnitTypeName":
        await measureUnitTypes.deleteMeasureUnit(this.itemToDelete);
        this.getMeasureUnitTypes();
        break;
      case "customerTypeName":
        await customerTypes.deleteCustomerType(this.itemToDelete);
        this.getCustomerTypes();
        break;
      case "name":
        await cities.deleteCity(this.itemToDelete);
        this.getUsedCities();
        break;
    }
    this.$set(this.tableLists, "itemsBeforeDelete", [] as CascadeDelete[]);
    this.showOnDeleteSpecialMessage = false;
    this.dialogDelete = false;
    snackbarModule.showSnackbar({
      message: "Eliminazione avvenuta con successo",
      type: "success",
    });
    setTimeout(() => snackbarModule.hideSnackbar(), 2000);
  }

  private getTagByCategory(id: number): unknown {
    if (id === 0) {
      let items = this.tableLists.tags.filter((tag) => tag.categoryID == null);
      return items;
    } else {
      let items = this.tableLists.tags.filter((tag) => tag.categoryID == id);
      return items;
    }
  }
}
