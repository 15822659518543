
import { Component, Prop, Vue } from "vue-property-decorator";
import { City } from "@/models/entities/city.interface";
import { cities } from "@/services/api/cities.service";
import { CityUpdate } from "@/models/utils/city-update.interface";
import { getModule } from "vuex-module-decorators";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class LocalitiesDialog extends Vue {
  @Prop({ required: true }) private show: boolean;
  @Prop({ required: true }) private title: string;
  @Prop({ required: true }) private label: string;
  @Prop({ required: true }) private itemType: string;

  private themeColor =
    "background-color: " +
    this.$vuetify.theme.themes.light.primary["lighten2"] +
    ";";
  private localitiesDialogProperties = {
    itemType: "",
  };
  private cities = [] as City[];
  private citiesHeader = [
    {
      text: "ID",
      value: "id",
    },
    {
      text: "Nome",
      value: "name",
    },
    {
      value: "provinceCode",
    },
    {
      text: "Codice postale",
      value: "postalCode",
    },
    {
      text: "Codice fiscale",
      value: "fiscalCodeID",
    },
    {
      text: "Codice istat",
      value: "istatCode",
    },
    {
      text: "Da verificare",
      value: "toCheck",
      filterable: false,
      sortable: false,
    },
    {
      text: "",
      filterable: false,
      sortable: false,
    },
  ];
  private citiesSearch = "";
  private cityUpdate = {} as CityUpdate;
  private toCheckFilter = false;

  mounted(): void {
    this.$set(this.localitiesDialogProperties, "itemType", this.itemType);
    this.getAllCities();
  }

  private async getAllCities(): Promise<void> {
    overlayModule.showOverlay();
    await cities.getAllCities(true, this.toCheckFilter).then((response) => {
      this.cities = response.data;
    });
  }

  private async addCity(id: number): Promise<void> {
    overlayModule.showOverlay();
    this.cityUpdate.cityID = id;
    this.cityUpdate.deliveryDays = [] as number[];
    this.cityUpdate.forewarningDays = 0;
    this.cityUpdate.forewarningTime = "00:00";
    await cities
      .updateCity(this.cityUpdate)
      .then(() => {
        snackbarModule.showSnackbar({
          message: "Salvataggio avvenuto con successo",
          type: "success",
        });
        setTimeout(() => snackbarModule.hideSnackbar(), 2000);
        this.getAllCities();
      })
      .catch(() => {
        snackbarModule.showSnackbar({
          message: "Qualcosa è andato storto durante il salvataggio",
          type: "error",
        });
        setTimeout(() => snackbarModule.hideSnackbar(), 2000);
      });
  }

  private closeLocalitiesDialog(): void {
    this.$emit("closeLocalitiesDialog", this.localitiesDialogProperties);
  }
}
