
import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { required } from "@/helpers/rules";
import { suppliers } from "@/services/api/suppliers.service";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";
import { Supplier } from "@/models/entities/supplier.interface";

const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class CategoryDialog extends Vue {
  @Prop({ required: true }) private show: boolean;
  @Prop({ required: true }) private title: string;
  @Prop({ required: true }) private label: string;
  @Prop({ required: true }) private itemType: string;

  private required = required;

  @Ref("categoryForm") readonly itemForm: HTMLFormElement;

  private isValid = false;
  private suppliers = [] as Supplier[];
  private categoryDialogProperties = {
    itemType: "",
    name: "",
    companyId: null,
    description: "",
  };

  async created(): Promise<void> {
    this.getAllSuppliers();
  }

  mounted(): void {
    this.$set(this.categoryDialogProperties, "itemType", this.itemType);
  }

  private async getAllSuppliers() {
    overlayModule.showOverlay();
    const s = await suppliers.getAllSuppliers();
    this.suppliers = s.data;
  }

  private closeCategoryDialog(): void {
    this.$emit("closeCategoryDialog", this.categoryDialogProperties);
  }

  private saveCategory(): void {
    this.isValid = this.itemForm.validate();
    if (this.isValid) {
      const s = this.suppliers.find(
        (el) => el.id == this.categoryDialogProperties.companyId
      );
      if (s) {
        this.$emit("saveCategory", this.categoryDialogProperties);
      } else {
        snackbarModule.showSnackbar({
          message: "Errore durante il caricamento della categoria",
          type: "error",
        });
        setTimeout(() => snackbarModule.hideSnackbar(), 2000);
      }
    }
  }
}
