
import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { required } from "@/helpers/rules";
import { ProductCategory } from "@/models/entities/product-category.interface";

@Component
export default class TagDialog extends Vue {
  @Prop({ required: true }) private show: boolean;
  @Prop({ required: true }) private title: string;
  @Prop({ required: true }) private label: string;
  @Prop({ required: true }) private itemType: string;
  @Prop({ required: true }) private colors: string[];
  @Prop({ required: true }) private productCategories: ProductCategory[];

  private required = required;

  @Ref("tagForm") readonly itemForm: HTMLFormElement;
  private isGlobalTag = false;
  private isValid = false;
  private tagDialogProperties = {
    itemType: "",
    name: "",
    color: "",
    categoryId: null,
  };

  mounted(): void {
    this.$set(this.tagDialogProperties, "itemType", this.itemType);
  }

  private closeTagDialog(): void {
    this.$emit("closeTagDialog", this.tagDialogProperties);
  }

  private setGlobalTag(): void {
    this.tagDialogProperties.categoryId = null;
  }

  private saveTag(): void {
    this.isValid = this.itemForm.validate();
    if (this.isValid) {
      this.$emit("saveTag", this.tagDialogProperties);
    }
  }
}
