import { MeasureUnitType } from "@/models/entities/measureUnitType.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "measureunittype";

class MeasureUnitTypesService extends ApiService {
  getAllMeasureUnitTypes() {
    return this.getAll<MeasureUnitType[]>(CONTROLLER);
  }
  saveMeasureUnit(measure: MeasureUnitType) {
    return this.post<MeasureUnitType, number>(CONTROLLER, measure);
  }
  onDeleteMeasureUnit(id: number) {
    return this.getAll<unknown>(`${CONTROLLER}/${id}/ondelete`);
  }
  deleteMeasureUnit(id: number) {
    return this.delete<number, void>(CONTROLLER, id);
  }
}

export const measureUnitTypes = new MeasureUnitTypesService();
