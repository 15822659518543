
import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { required } from "@/helpers/rules";

@Component
export default class MeasureDialog extends Vue {
  @Prop({ required: true }) private show: boolean;
  @Prop({ required: true }) private title: string;
  @Prop({ required: true }) private label: string;
  @Prop({ required: true }) private itemType: string;

  private required = required;

  @Ref("measureForm") readonly itemForm: HTMLFormElement;

  private isValid = false;

  private measureDialogProperties = {
    name: "",
    itemType: "",
  };

  mounted(): void {
    this.$set(this.measureDialogProperties, "itemType", this.itemType);
  }

  private closeMeasureDialog(): void {
    this.$emit("closeMeasureDialog", this.measureDialogProperties);
  }

  private saveMeasure(): void {
    this.isValid = this.itemForm.validate();
    if (this.isValid) {
      this.$emit("saveMeasure", this.measureDialogProperties);
    }
  }
}
